@font-face {
  font-family: "Horizon";
  src: local("Horizon"),
    url("./assets/fonts/horizon.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Circular";
  src: local("Circular"),
    url("./assets/fonts/CircularXXWeb-Regular.woff") format("woff"),
    url("./assets/fonts/CircularXXWeb-Regular.woff2") format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: "VT323";
  src: local("VT323-Regular"),
    url("./assets/fonts/VT323-Regular.ttf") format("truetype");
  font-weight: normal;
}

html, body, .MuiButton-root, .MuiButton-root,
button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day {
  cursor: url("./assets/images/cursor/EarthGlobeAmericasEmoji.png"), auto !important;
  font-family: "Circular";
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  width: 120px;
  height: 120px;
  margin-left: 3vw;
  margin-top: 2vw;
}

.MuiButton-label {
  font-family: "Circular"
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiIconButton-root {
  color: black !important;
}

.MuiPickersCalendarHeader-switchHeader {
  display: block !important;
  position: relative !important;
  width: 66vw !important;
  margin-bottom: 20px !important;
  margin-top: 8px !important;
}

.MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-transitionContainer p {
  width:  70% !important;
  cursor: pointer;
  font-size: 1.33em;
  text-align: left !important;
  padding-left: 15px !important;
  background-color: rgba(0,0,0,0.0) !important;
}

.MuiPickersCalendarHeader-switchHeader p {
  font-family: Horizon !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:before,
.MuiSelect-select {
  border-bottom: none !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

li.MuiListItem-button {
  font-family: "Circular" !important;
}

.MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
  width: 66vw;
  display: inline-block;
  overflow: hidden;
}

.calendar__greenBlueYellow {
  background: linear-gradient(56deg, rgba(250,255,0,0.15) 0%, rgba(74,255,147,0.15) 40%, rgba(117,222,255,0.15) 100%);
}

.calendar__pinkWhite {
  background: linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%);
}

.calendar__yellowPink {
  background: linear-gradient(56deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%);
}

.calendar__pinkRed {
  background: linear-gradient(56deg, #F1BEC1  0%, #E42929 100%);
}

.calendar__blackBlue{
  background: linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%);
}


.calendar__blackBlue .MuiPickersDay-daySelected {
  background: rgb(156, 146, 138);
}

.calendar__blackBrown{
  background: linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%);
}

.calendar__blackBrown .MuiPickersDay-daySelected {
  background: rgb(156, 146, 138);
}

.calendar__blackGray {
  background: linear-gradient(33deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%);
}

.calendar__blackGray .MuiPickersDay-daySelected {
  background: rgb(156, 146, 138);
}

.calendar__brownPink {
  background: linear-gradient(33deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%);

}

.calendar__brownPink .MuiPickersDay-daySelected {
  background: rgb(156, 146, 138);
}

.calendar__whiteLavender {
  background: linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%);
}

.button__greenBlueYellow {
  background: linear-gradient(180deg, #DDF9FB 0%, #EAFFF2 100%);
}

.button__pinkWhite {
  background: linear-gradient(to right, rgb(251,203,219) 0%, rgb(255, 252, 254) 100%);
}

.button__pinkRed {
  background: linear-gradient(56deg, #F1BEC1  0%, #E42929 100%);
}

.button__yellowPink {
  background: linear-gradient(180deg, rgb(248, 240, 120) 0%, rgb(225, 154, 210) 100%);
}

.button__blackBlue{
  background: linear-gradient(180deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%);
}

.button__blackGray {
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(115, 115, 115) 100%);
}

.button__brownPink {
  background: linear-gradient(180deg, rgb(203, 134, 76) 0%, rgb(253, 195, 199) 100%)
}

.button__blackBlue{
  background: linear-gradient(180deg, rgb(4, 0, 30) 0%, rgb(0, 143, 179) 100%);
}

.button__blackBrown{
  background: linear-gradient(33deg, rgb(4, 0, 30) 0%, rgb(157, 60, 16) 100%);
}

.button__whiteLavender {
  background: linear-gradient(180deg, rgb(248, 245, 233) 0%, rgb(245, 233, 248) 100%);
}

button.MuiPickersCalendarHeader-iconButton {
  position: absolute !important;
  top: -10px !important;
  right: 0 !important;
  margin-right: 30px !important;
  background: rgba(0,0,0,0.0) !important;
  cursor: pointer !important;
}

button.MuiPickersCalendarHeader-iconButton:first-child {
  right: 50px !important;
}

.MuiPickersCalendar-transitionContainer {
  margin-top: 0px !important;
  min-height: 85% !important;
  overflow-y: scroll !important;
}

.MuiPickersSlideTransition-transitionContainer > * {
    width: 62.0vw !important;
    margin-left: 20px !important;
}

.MuiPickersSlideTransition-transitionContainer > div:first-child {
  box-shadow: 3.9893px 3.9893px 3.9893px rgba(218, 171, 255, 0.15), inset 0px 3.9893px 9.97326px rgba(0, 91, 227, 0.11);
  border-radius: 6px;
  /*background: linear-gradient(56deg, rgba(250,255,0,0.15) 0%, rgba(74,255,147,0.15) 40%, rgba(117,222,255,0.15) 100%);*/
}

.MuiPickersCalendar-week {
  /*width: 56vw !important;*/
  margin: 0 auto !important;
}

.MuiPickersDay-day {
  width: 8.5vw !important;
  height: 8.5vw !important;
  border: 2px gray;
  border-radius: 2px !important;
  padding: .66em !important;
}

.MuiPickersDay-day ~ div,
.MuiPickersDay-day ~ div > div {
  padding-bottom: 2px !important;
  padding-right: 2px !important;
}

.MuiTypography-body1 {
  color: rgba(0, 0, 0, 1.0) !important;
  font-weight: bold !important;
  background: white !important;
  font-family: "Circular" !important;
}

.MuiTypography-body2 {
  font-weight: bold !important;
  font-size: 1.0rem !important;
}

.MuiFormLabel-root {
  font-family: 'Circular' !important;
}

.MuiPickersCalendarHeader-daysHeader {
  width: 66vw !important;
}

.MuiPickersCalendarHeader-dayLabel {
  width: 8.5vw !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 1.0) !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}

.MuiIconButton-label {
  justify-content: left !important;
}

.MuiButtonBase-root {
   align-items: start !important;
}

.MuiTabs-root {
  width: 85% !important;
  max-height: 40px !important;
  border-radius: 6px !important;
  border: 1px solid black !important;
}

.MuiTabPanel-root {
  padding: 0px !important;
}

span.MuiTabs-indicator {
  display: none !important;
}

header.MuiAppBar-root {
  box-shadow: none !important;
}

button.MuiPickersCalendarHeader-iconButton.Mui-disabled {
  color: #F5FAFF !important;
}

#tab-bar-view-control header button {
  cursor: pointer !important;
}

#tab-bar-view-control header button span:first-child {
  font-size: 32px !important;;
  line-height: 34px !important;;
}

#tab-bar-view-control header button:first-child.Mui-selected {
  border-right: 1px solid black !important;
}

#tab-bar-view-control header button:nth-child(2).Mui-selected {
  border-left: 1px solid black !important;
}

#tab-bar-view-control header .MuiTab-textColorInherit {
  opacity: 1.0 !important;;
}

.ql-container.ql-snow {
  height: 250px !important;
  overflow: scroll !important;
  font-family: 'Circular' !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(225deg) !important;
}

#firebaseui_container form button.firebaseui-idp-button {
  height: 60px !important;
  width: 300px !important;
}

#firebaseui_container form li.firebaseui-list-item:first-child button.firebaseui-idp-button {
  background: linear-gradient(to right, rgba(241,193,193,255) 0%, rgba(245,227,138,255) 100%) !important;
}

#firebaseui_container form li.firebaseui-list-item:last-child button.firebaseui-idp-button {
  background: linear-gradient(to right, rgba(245,227,138,255) 0%, rgba(241,193,193,255) 100%) !important;
}

#firebaseui_container form button.firebaseui-idp-button span {
  color: #000;
}

#firebaseui_container form button.firebaseui-idp-button svg {
  fill: #000 !important;
}

.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking {
  width: 330px !important;
  height: 330px !important;
  padding: 30px !important;
  padding-top: 60px !important;
  border-radius: 50% !important;
  background: linear-gradient(to bottom, rgb(241, 190, 193) 0%, rgb(255, 252, 254) 100%) !important;
  border: 1px solid black !important;
}

.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking {
  max-width: unset !important;
  min-width: unset !important;
  width: 500px !important;
  height: 500px !important;
  padding: 62px !important;
}

.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in input,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up input,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in input,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery input {
  background-color: rgba(0,0,0,0.0) !important;
}

.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking div.firebaseui-card-actions {
  margin-top: 20px !important;
}

h1.firebaseui-title {
  padding-bottom: 30px;
  font-family: "Circular";
  text-align: center;
  text-transform: lowercase;
}

.firebaseui-text {
  font-family: "Circular" !important;
}

label.mdl-textfield__label.firebaseui-label {
  font-family: 'Horizon';
}

.firebaseui-form-actions {
  text-align: center !important;
}

.mdl-button--primary.mdl-button--primary {
  color: #333 !important;
}

.mdl-button--raised.mdl-button--colored {
  background-color: #333 !important;
}

#user-guide li::marker {
  content: "\2714";
}

#user-guide ul li ul li::marker {
  content: unset;
}


@media (max-width: 1440px) {
  .MuiPickersDay-day {

  }

  .MuiPickersCalendarHeader-dayLabel {
  }

  .MuiPickersSlideTransition-transitionContainer {
    width: 75vw !important;
  }

  .MuiPickersSlideTransition-transitionContainer > * {
    width: 62.75vw !important;
    margin-left: 14px !important;
  }

  .ql-container.ql-snow {
    height: 190px !important;
  }

}
/*mini touch screen laptop --> loses bottom of note editor*/
@media (max-width: 1280px) and (min-width: 1025px) {
  .ql-container.ql-snow {
    height: 125px !important;
  }
}

@media (max-width: 1024px) {
  html {
    overflow-x: hidden !important;
  }

  .App-logo {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: 80px;
    height: 80px;
    margin-right: 3vw;
  }

  .MuiPickersSlideTransition-transitionContainer {
    width: 100% !important;
    min-height: 820px !important;
  }

  .MuiPickersCalendarHeader-switchHeader {
    width: 100vw !important;
    height: 1.5em;
  }

  .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer,
  .MuiPickersCalendarHeader-daysHeader {
    width: 100vw !important;
  }

  .MuiPickersSlideTransition-transitionContainer > * {
    width: 95.25vw !important;
    margin-left: 2.375% !important;
  }

  .MuiPickersCalendarHeader-dayLabel {
    width: 13vw !important;
  }

  .MuiPickersDay-day {
    width: 13vw !important;
    height: 13vw !important;
  }

  .firebaseui-card-actions {
    margin-top: -20px;
  }

}

@media (max-width: 900px) {
  .MuiPickersSlideTransition-transitionContainer {
    min-height: 645px !important;
  }
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking {
    margin-top: 0;
    max-height: 420px !important;
    max-width: 420px !important;
    width: 420px !important;
    height: 420px !important;
    padding: 55px !important;
  }
  .firebaseui-title {
    line-height: 20px !important;
    padding-bottom: 15px !important;
  }
}

@media (max-width: 770px) {
  .firebaseui-title {
    line-height: 18px !important;
  }
}

@media (max-width: 700px) {
  .MuiPickersSlideTransition-transitionContainer {
    min-height: 300px !important;
    max-height: 400px !important;
    height: 340px !important;
  }
  .MuiPickersSlideTransition-transitionContainer > * {
    width: 99% !important;
    margin-left: 1px !important;
  }
}

@media (max-width: 600px) {
  button.MuiPickersCalendarHeader-iconButton {
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mdl-textfield,
  .firebaseui-subtitle {
    font-size: 13px;
    padding: 15px 0 !important;
  }

  .firebaseui-label {
    font-size: 13px !important;
  }
}

@media (max-width: 480px) {
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking {
    width: 98vw !important;
    height: 98vw !important;
    padding-top: 38px !important;
  }

  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in form,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in div.firebaseui-card-content {
    margin-top: 20px;
  }

  h1.firebaseui-title {
    margin-top: -20px !important;
  }

  .firebaseui-card-header {
    width: 80% !important;
    margin: 0 auto !important;
    margin-bottom: 8px !important;
    padding-bottom: 5px !important;
  }

  .firebaseui-card-actions {
    padding: 8px 10px 10px !important;
    padding-top: 0 !important;
  }

  .firebaseui-button {
    margin-left: 0px !important;
  }

  #tab-bar-view-control header button span:first-child {
    font-size: 28px !important;
  }

  .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-transitionContainer p {
    font-size: .88em;
  }
}

@media (max-width: 320px) {
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-recovery,
  .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-federated-linking {
    height: 60vh !important;
    border-radius: 12px !important;
    padding: 30px !important;
  }
  .firebaseui-card-content {
    padding: 0 !important;
  }

  label.mdl-textfield__label.firebaseui-label {
    font-size: 11px !important;
  }

  .firebaseui-card-actions {
    padding: 0 !important;
  }
}

@media (min-width: 600px) {
  .MuiTabs-root button.MuiTab-root {
    min-width: unset;
  }
}
